const dev = {
SERVER: '',
GM_API_KEY: 'AIzaSyClLpOFxDkYZypbH2P6avw4AazGk_RhUOs',
FORM_SERVER: 'http://localhost:3001',
PAGE_FOOTER: 11014
}

const prod ={
  SERVER: 'https://cms.hylyt.net',
 GM_API_KEY: 'AIzaSyClLpOFxDkYZypbH2P6avw4AazGk_RhUOs',
 FORM_SERVER: '',
 PAGE_FOOTER: 11023
}

//tässä tallessa mapsin tuotantotunnarit tod näk
const mapprod={
  SERVER: 'https://cms.hylyt.net',
 GM_API_KEY: 'AIzaSyCl2g7Srrvcbpe5TiXyLBPDAQfNcfIZnsY',
 FORM_SERVER: '',
 PAGE_FOOTER: 11023
}

const test ={
  SERVER: 'https://beta.hylyt.net',
 GM_API_KEY: 'AIzaSyClLpOFxDkYZypbH2P6avw4AazGk_RhUOs',
 FORM_SERVER: '',
 PAGE_FOOTER: 11023
}

const cmsbeta ={
  SERVER: 'https://cmsbeta.hylyt.net',
 GM_API_KEY: 'AIzaSyClLpOFxDkYZypbH2P6avw4AazGk_RhUOs',
 FORM_SERVER: '',
 PAGE_FOOTER: 11023
}
const cmsalpha ={
  SERVER: 'https://cmsalpha.hylyt.net',
 GM_API_KEY: 'AIzaSyClLpOFxDkYZypbH2P6avw4AazGk_RhUOs',
 FORM_SERVER: '',
 PAGE_FOOTER: 11023
}

const config = process.env.REACT_APP_STAGE === "prod" 
  ?prod 
  :process.env.REACT_APP_STAGE === "dev"
    ?dev
    :process.env.REACT_APP_STAGE === "test"
      ?test
      :process.env.REACT_APP_STAGE === "mapprod"
        ?mapprod
        :process.env.REACT_APP_STAGE === "cmsbeta"
          ?cmsbeta
          :process.env.REACT_APP_STAGE === "cmsalpha"
            ?cmsalpha
            :prod; 

export default {
 
  ...config,
};